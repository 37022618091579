/* Add keyframes for the jumping animation */
@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Add this new class for the blurred state of the scrollable content with jumping animation */
.scrollable-content.blur {
  filter: blur(2px);
  animation: jump 0.5s infinite;
}

/* Existing styles */
.product-display {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  position: relative; /* Keep this for positioning the scrollable content */
  height: 80vh;
  z-index: 1; /* Ensure it's above the background image */
}

/* Position the title and image statically */
.product-display h2,
.product-display img {
  position: static; /* This will keep them in the normal document flow */
  z-index: 2; /* Higher z-index to ensure they are above the scrollable content */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for the scaling effect */
}

/* Styles for the title */
.product-display h2 {
  color: white;
  margin-bottom: 10px;
}

/* Styles for the image */
.product-display img {
  display: block;
  max-width: 30vh;
  height: auto;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 10px;
  z-index: 3; /* Ensure the image is above the background */
}

/* Container for the scrollable content */
.scrollable-content {
  position: absolute; /* Positioned absolutely within the product-display */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 52vh; /* Adjust this value based on the actual height of the title and image */
  padding-bottom: 30vh;
  overflow-y: auto; /* Enables vertical scrolling */
  z-index: 10 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.product-display p {
  color: white; /* Medium grey color for the description */
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: left;
}

.product-display .features {
  color: white;
  list-style: none; /* Remove default list styling */
  padding: 0;
}

.product-display .features li {
  padding: 5px 10px;
  margin-bottom: 5px;
}

.product-display .reviews {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #9aa29b;
  text-align: left;
  font-style: italic;
}

.product-display hr {
  border: 0; /* Remove default border */
  height: 1px; /* Set the height of the line */
  background-color: #eaeaea; /* Light grey color for the line */
  margin: 20px 0; /* Add some vertical spacing around the line */
}

.shrink {
  transform: scale(0.9); /* Reduces size to 90% of the original */
  filter: blur(5px);
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1; /* Ensure it's below the main image and content */
}

.blur {
  filter: blur(5px);
}

.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.counter-button {
  background-color: #4CAF50; /* Example green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 5px 10px; /* Adjust padding as needed */
  margin: 0 5px; /* Space between buttons and counter value */
  font-size: 1rem; /* Adjust font size as needed */
}

.counter-value {
  color: white;
  font-size: 1.5rem;
  margin: 0 10px; /* Space between buttons and counter value */
}

.counter-button:disabled {
  background-color: #ccc; /* Example gray color to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
}

.add-button {
  background-color: #4CAF50; /* Example green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 10px 20px; /* Adjust padding as needed */
  margin-top: 10px; /* Space above the button */
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
  z-index: 4; /* Ensure it's above other content */
  position: relative; /* Establish a positioning context */
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;
}

/* Styles for the added-label */
.added-label {
  color: white;
  background-color: rgba(76, 175, 80, 0.9); /* Slightly transparent green background */
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  top: 10px; /* Position it at the top of the product-display container */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Start fully transparent */
  visibility: hidden; /* Start hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
  z-index: 15; /* Ensure it's above all other content */
}

/* When the label is supposed to be shown, we add this class to change its opacity and visibility */
.added-label-show {
  opacity: 1; /* Fully visible */
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease
}

/* When the label is supposed to be hidden, we add this class to change its opacity and visibility */
.added-label-hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.add-button:disabled {
  background-color: #ccc; /* Example gray color to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
}

.image-container {
  display: flex;
  align-items: center;
  z-index: 3; /* Ensure the image container is above the background */
}

.image-selector {
  display: flex;
  flex-direction: column;
  margin-left: 5px; /* Space between selector and main image */
  z-index: 4; /* Ensure the image selector is above the image */
  pointer-events: auto; /* Ensure the image selector can receive pointer events */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for the scaling effect */
}

.image-selector.shrink {
  transform: scale(0.9); /* Reduces size to 90% of the original */
  filter: blur(5px);
  z-index: 9; /* Lower z-index when shrunk */
}

.image-selector-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  margin-bottom: 5px; /* Space between buttons */
  transition: transform 0.3s ease;
  z-index: 5; /* Ensure the buttons are above other elements */
  pointer-events: auto; /* Ensure the buttons can receive pointer events */
}

.image-selector-button img {
  width: 20px; /* Adjust the size of the thumbnails */
  height: auto;
  border-radius: 4px;
}

.image-selector-button.active img {
  border: 2px solid #4CAF50; /* Highlight the active thumbnail */
  transform: scale(1.2); /* Slightly enlarge the active thumbnail */
}

.videos {
  margin: 20px 0; /* Add some vertical spacing around the videos */
}

.videos video {
  width: 100%; /* Make the video take up the full width of the container */
  max-width: 600px; /* Set a maximum width for the video */
  height: auto; /* Maintain the aspect ratio */
  display: block;
  margin: 10px auto; /* Center the video horizontally */
  border-radius: 8px; /* Add some border radius for rounded corners */
}

.title-selector {
  display: flex;
  flex-direction: column;
  margin-left: 20px; /* Space between selector and main image */
  z-index: 4; /* Ensure the title selector is above the image */
  pointer-events: auto; /* Ensure the title selector can receive pointer events */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for the scaling effect */
}

.title-selector.shrink {
  transform: scale(0.9); /* Reduces size to 90% of the original */
  filter: blur(5px);
  z-index: 9; /* Lower z-index when shrunk */
}

.title-selector-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1px;
  margin-bottom: 5px; /* Space between buttons */
  transition: transform 0.3s ease;
  z-index: 5; /* Ensure the buttons are above other elements */
  pointer-events: auto; /* Ensure the buttons can receive pointer events */
}

.title-selector-button img {
  width: 20px; /* Adjust the size of the thumbnails */
  height: auto;
  border-radius: 4px;
}

.title-selector-button.active img {
  border: 2px solid #4CAF50; /* Highlight the active title */
  transform: scale(1.2); /* Slightly enlarge the active title */
}
