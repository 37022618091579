.age-verification {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start (top) */
  align-items: center;
  height: 100vh;
  background-color: #0a0601; /* Set the background color */
  text-align: center;
  padding-top: 0; /* Remove any padding from the top */
  margin-top: 0; /* Remove any margin from the top */
}

.verification-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add some space below the image */
  margin-top: 0; /* Ensure no margin at the top */
}

.age-verification-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: white; /* Ensure the text is readable on the dark background */
}

.age-verification-subtitle {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: white; /* Ensure the text is readable on the dark background */
  padding: 10px;
}

.age-verification button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
}

.age-verification button:hover {
  background-color: #45a049;
}
