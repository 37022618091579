body, html {
  height: 100%;
  overflow: hidden; /* Prevent scrolling on the entire page */
  margin: 0; /* Remove default margin */
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
}

.App-logo {
  height: 13vh;
  position: absolute;
  top: 0.3rem;
  left: 0.7rem;
  pointer-events: auto;
  cursor: pointer;
}

.App-logo-main {
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 70%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the button */
  padding: 0; /* Remove padding */
}

/* Ensure the image inside the button fills the button */
.App-logo-main img {
  height: 23vh; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-shake 2s ease-in-out infinite;
  }
}

.App-header {
  background-color: #0a0601;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 11.5%; /* Define the height of the header */
  z-index: 1002; /* Make sure it's above other content */
  /* Ensure the rest of your header styles are here */
}

.App-body {
  background-color: #0a0601;
  position: fixed;
  top: 11.5%; /* Set this to the height of your header */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto; /* Allow scrolling within the App-body */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  width: 100%;
}

.home {
  padding: 20px 0;
  color: white;
}

.App-link {
  color: #61dafb;
}

.side-menu {
  position: fixed;
  top: 0;
  left: -100%; /* Start off-screen to the left */
  width: 350px; /* Width of the menu */
  height: 100%;
  background-color: #001502;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease-in-out;
  z-index: 1004; /* Ensure the menu is above other content */
}

.side-menu.visible {
  left: 0; /* Slide in from the left */
}

/* Additional styles for the close button */
.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px; /* Size of the close button */
  height: 24px;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other content */
}

/* Styles for the lines */
.line1, .line2 {
  position: absolute;
  left: 11px;
  content: '';
  height: 24px;
  width: 2px;
  background-color: white;
}

.line1 {
  transform: rotate(45deg);
}

.line2 {
  transform: rotate(-45deg);
}

.burger-button {
  position: absolute;
  top: 1.9rem;
  left: 1.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem; /* Width of the burger button */
  height: 2rem; /* Height of the burger button */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  z-index: 1003; /* Ensure it's above other content */
}

.burger-line {
  width: 2rem; /* Match the width of the burger button */
  height: 0.2rem; /* Thickness of the burger lines */
  background: white; /* Color of the burger lines */
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}


/* Styles for the side menu logo */
.menu-logo {
  padding: 20px;
  text-align: center; /* Center the logo */
}

.menu-logo img {
  display: block; /* Ensure the logo is visible */
  margin: 0 auto; /* Center the logo horizontally */
  /* The rest of the .menu-logo img styles remain unchanged */
}

.menu-react-logo {
  width: 150px; /* Set a fixed width for the logo */
  height: auto; /* Maintain aspect ratio */
}

/* Styles for the side menu description */
.menu-description {
  padding: 0 20px; /* Add padding on the sides */
  text-align: justify; /* Justify the text for better readability */
  font-size: 0.9rem; /* Adjust the font size as needed */
  line-height: 1.4; /* Adjust line height for better readability */
  color: white; /* Dark grey color for the text */
}

.sub-menu-description {
  padding: 50px 20px; /* Add padding on the sides */
  text-align: justify; /* Justify the text for better readability */
  font-size: 0.7rem; /* Adjust the font size as needed */
  line-height: 1.4; /* Adjust line height for better readability */
  color: white; /* Dark grey color for the text */
}

.menu-toggle-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin: 1rem;
  background-color: #077511; /* React blue color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.menu-toggle-button:hover {
  background-color: #141714; /* Darker blue on hover */
}

.saber-mas-btn {
  padding: 0.5rem 1rem;
  background-color: #4CAF50; /* Example green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  position: absolute; /* Position the arrows absolutely within the sub-menu */
  left: 10%;
  right: 10%;
  bottom: 15px; /* Position 10px above the bottom */
}

/* Styles for the second sliding menu */
.sub-side-menu {
  position: fixed;
  bottom: -100%; /* Start off-screen at the bottom */
  left: 0;
  width: 100%;
  height: 50%; /* Half the height of the viewport */
  background-color: #33220a; /* Darker shade for the second menu */
  transition: bottom 0.3s ease-in-out;
  z-index: 1001; /* Above the first sliding menu */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.8);
}

.sub-side-menu.visible {
  bottom: 0; /* Slide in from the bottom */
}

/* Styles for the close button of the second sliding menu */
.close-sub-menu {
  padding: 0.6rem 2rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transform: translateX(30%); /* Correctly center align the button */
  position: absolute;
  top: 10px; /* Position at the top */
  z-index: 2; /* Ensure it's above the text content */
}

.text-slider-arrows {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  position: absolute; /* Position the arrows absolutely within the sub-menu */
  left: 0;
  right: 0;
  bottom: 10px; /* Position 10px above the bottom */
}

.text-slider-arrows button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.text-slider-arrows button:disabled {
  color: grey;
  cursor: default;
}

.menu-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create a 2-column grid */
  grid-auto-rows: 1fr; /* Make grid rows take up available space */
  gap: 10px; /* Space between grid items */
  padding: 10vh 10vw; /* Add some padding above and below the button group */
}

/* Styles for square buttons */
.menu-button-square {
  grid-column: span 1; /* Each square button takes up one column */
}

/* Styles for rectangle buttons */
.menu-button-rectangle {
  grid-column: 1 / -1; /* Rectangle buttons span the full width */
}

/* Styles for the menu-button */
.menu-button {
  background-color: #097130; /* Example green color */
  position: relative; /* Establish a new stacking context */
  overflow: hidden;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  padding: 10px; /* Padding inside the buttons */
  margin: 5px 0; /* Margin between buttons */
  width: 100%; /* Set a width for the buttons */
  height: 150%;
  box-sizing: border-box; /* Include padding and border in the width */
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
  animation: fadeInUp 0.5s ease forwards;
}

/* Ensure the animation for the menu-button-wrapper is still applied */
.menu-button-wrapper {
  opacity: 0; /* Start with the wrapper hidden */
  transform: translateY(20px); /* Start with the wrapper slightly lower */
  animation: fadeInUp 0.5s ease forwards;
}

.menu-button:hover {
  background-color: #367c39; /* Darker green on hover */
}

.product-info-menu {
  position: fixed;
  top: 13.0%; /* Position the top of the menu just below the header */
  bottom: 0; /* Stretch to the bottom of the viewport */
  left: 0;
  width: 100%;
  background-color: #0a0601; /* Choose a suitable background color */
  transition: transform 0.3s ease-in-out;
  z-index: 1001; /* Below the header but above other content */
  transform: translateY(100%); /* Start off-screen, hidden below the viewport */
  overflow: hidden;
}

.product-info-menu.visible {
  transform: translateY(0); /* Slide in to cover the App-body */
}

.product-info-menu .product-section {
  padding-top: 10px; /* Add padding to the top of the product section */
  padding-bottom: 20px; /* Add padding to the bottom of the product section */
}

/* Styles for individual product items */
.product-info-menu .product-item {
  padding: 10px 0; /* Add padding above and below each product item */
  /* You can adjust the padding values as needed */
}

.close-product-info-btn {
  position: absolute;
  top: 40px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  width: 15vw; /* Adjust the size as needed */
  height: 15vw; /* Adjust the size as needed */
  border: none;
  background: none; /* No background color */
  cursor: pointer;
  z-index: 1003; /* Ensure it's above the sliding menu content */
  padding: 0; /* Remove padding */
}

/* Styles for the back button image */
.back-button-img {
  width: 100%; /* Make the image fill the button */
  height: auto; /* Maintain aspect ratio */
  animation: back-button-animation 3s ease-in-out infinite;
}

.close-product-info-btn:active .back-button-img {
  /* Optional: Style for when the button is clicked */
}

.product-info-menu .footer-images-container {
  position: absolute;
  bottom: 0; /* Align to the bottom of the product-info-menu */
  width: 100%; /* Span the full width */
  /* ...existing styles... */
}

.footer-layer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 9%; /* Adjust as needed */
  z-index: 1001;
}

.footer-layer-1 {
  background-color: rgba(117, 133, 117, 0.7); /* Example color */
  clip-path: polygon(27% 29%, 40% 32%, 53% 41%, 69% 55%, 84% 66%, 100% 72%, 100% 100%, 0 100%, 0 43%, 12% 33%);
  z-index: 1003;
}

.footer-layer-2 {
  background-color: rgba(40, 138, 40, 0.7); /* Example color */
  clip-path: polygon(18% 21%, 31% 27%, 43% 39%, 51% 68%, 75% 83%, 89% 89%, 100% 100%, 0 100%, 0 0, 7% 13%);
  z-index: 1002;
}

.footer-layer-3 {
  background-color: rgba(196, 190, 12, 0.7); /* Example color */
  clip-path: polygon(0 100%, 11% 87%, 23% 75%, 32% 63%, 44% 54%, 54% 41%, 58% 30%, 65% 25%, 72% 23%, 79% 24%, 86% 27%, 91% 32%, 95% 38%, 96% 45%, 99% 50%, 100% 60%, 100% 69%, 100% 78%, 100% 83%, 100% 93%, 100% 100%);
  z-index: 1004;
}

.footer-layer-4 {
  background-color: rgba(163, 106, 34, 0.7); /* Example color */
  clip-path: polygon(100% 12%, 96% 12%, 91% 15%, 88% 17%, 85% 20%, 83% 25%, 80% 26%, 78% 29%, 74% 32%, 72% 35%, 70% 37%, 66% 41%, 64% 46%, 59% 53%, 56% 58%, 53% 64%, 48% 69%, 41% 72%, 37% 73%, 32% 77%, 26% 77%, 22% 79%, 16% 82%, 10% 85%, 7% 87%, 4% 90%, 2% 94%, 0 100%, 100% 100%);
  z-index: 1005;
}

.pseudo-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7%; /* EL FOOTER */
  padding: 10px 20px; /* Adjust padding as needed */
  background: #141714; /* Match the background color of the footer */
  display: flex; /* Use flexbox to distribute points */
  justify-content: space-between; /* Distribute points evenly */
  align-items: center; /* Center points vertically */
  box-sizing: border-box;
  overflow: visible; /* Allow contents to extend outside the footer */
  z-index: 1;
}

.footer-images-container {
  position: absolute;
  bottom: 0; /* Align to the bottom of the App-body */
  width: 100%; /* Span the full width */
  display: flex; /* Use flexbox to distribute images */
  justify-content: space-between; /* Distribute images evenly */
  padding: 10px 20px; /* Padding on the sides */
  z-index: 1010;
}

.footer-image-container {
  width: 50px; /* Fixed width for the container */
  height: 50px; /* Fixed height for the container */
  border-radius: 50%; /* Make the container circular */
  background-color: #393c39; /* Set the background color */
  display: flex; /* Use flexbox for centering the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  position: relative; /* Establish a stacking context */
  z-index: 1020;
}

.footer-image {
  width: auto; /* Maintain the original aspect ratio */
  height: 40px; /* Set a fixed height for the images */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  opacity: 0.5;
}

.footer-point {
  display: inline-block;
  width: 10px; /* Fixed width */
  height: 10px; /* Fixed height */
  border-radius: 50%; /* Maintain the circular shape */
  background-color: white;
  transition: transform 0.3s ease-in-out;
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
}

.footer-point.active {
  transform: scale(1.5); /* Increase the size of the active point */
}

.footer-image-container.active .footer-image {
  transform: scale(1.5); /* Slightly increase the size of the active image */
  opacity: 1; /* Full opacity for the active image */
  z-index: 3;
}

.whatsapp-button {
  height: 15vh; /* Adjust the size as needed */
  position: absolute;
  top: 0.01rem;
  right: 0.1rem;
  cursor: pointer;
}

.cart-window {
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen to the right */
  width: 350px; /* Width of the cart window */
  height: 100%;
  background-color: #001502;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in-out;
  color: white;
  z-index: 1004; /* Ensure the cart is above other content */
}

.cart-window h2 {
  padding: 10px; /* Adjust the padding as needed */
  /* Other styles for the h2 tag */
}

.cart-window.visible {
  right: 0; /* Slide in from the right */
}

/* Additional styles for the close button */
.close-cart {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px; /* Size of the close button */
  height: 24px;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other content */
}

/* Styles for the cart items */
.cart-items {
  padding: 20px;
  /* Add more styles as needed */
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ccc; /* Optional: add a separator between items */
  margin-bottom: 20px;
}

.cart-item:last-child {
  margin-bottom: 0; /* Remove the bottom margin for the last item */
}

.comprar-btn {
  padding: 0.5rem 1rem;
  background-color: #4CAF50; /* Example green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  position: absolute; /* Position the button at the bottom */
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%); /* Center the button */
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Take up the full height of the cart window */
  padding: 20px; /* Add some padding */
}

/* Styles for the empty cart image */
.empty-cart-image {
  max-width: 100%; /* Ensure the image is not wider than the container */
  max-height: 50%; /* Adjust the height as needed */
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  background-color: #4CAF50; /* Example green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 5px 10px; /* Adjust padding as needed */
  margin: 0 5px; /* Space out the buttons from the quantity */
}

.section-image {
  position: absolute; /* Position the image absolutely within the button */
  top: 0;
  left: -20%;
  width: 80%; /* Fill the entire button */
  height: 100%; /* Fill the entire button */
  object-fit: cover; /* Cover the area without stretching */
  z-index: -1; /* Place the image behind the text */
  opacity: 0.8;
}

@keyframes App-logo-shake {
  0%, 50%, 100% {
    transform: rotate(0deg);
  }
  12.5%, 37.5% {
    transform: rotate(15deg); /* Rotate to the right */
  }
  25% {
    transform: rotate(-15deg); /* Rotate to the left */
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes back-button-animation {
  0%, 100% {
    transform: translateX(0) scaleX(-1); /* Maintain flipped state */
  }
  25%, 75% {
    transform: translateX(-10px) scaleX(-1); /* Move to the left and maintain flipped state */
  }
  50% {
    transform: translateX(10px) scaleX(-1); /* Move to the right and maintain flipped state */
  }
}

.underage-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0a0601; /* Set the background color */
  text-align: center;
}

.prohibited-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.proximamente-image {
  display: block;
  max-width: 100%; /* Adjust the maximum width as needed */
  max-height: 80vh; /* Adjust the maximum height as needed */
  margin: 0 auto; /* Center the image horizontally */
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
  padding: 1px; /* Add some padding around the image */
}

.logo-container {
  position: relative;
  height: 23vh; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
}

.logo-container img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.1s ease, transform 0.3s ease; /* Add transform transition */
}

.logo-container .main-logo {
  animation: slideInFromBottom 0.2s forwards;
}

.logo-container .main-logo-exit {
  animation: slideOutToTop 0.2s forwards;
}

.logo-container .title-image {
  animation: slideInFromTop 0.2s forwards;
}

.logo-container .title-image-exit {
  animation: slideOutToBottom 0.2s forwards;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%) translateX(-50%); /* Maintain horizontal centering */
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%); /* Maintain horizontal centering */
    opacity: 1;
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0) translateX(-50%); /* Maintain horizontal centering */
    opacity: 1;
  }
  100% {
    transform: translateY(-100%) translateX(-50%); /* Maintain horizontal centering */
    opacity: 0;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%) translateX(-50%); /* Maintain horizontal centering */
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%); /* Maintain horizontal centering */
    opacity: 1;
  }
}

@keyframes slideOutToBottom {
  0% {
    transform: translateY(0) translateX(-50%); /* Maintain horizontal centering */
    opacity: 1;
  }
  100% {
    transform: translateY(100%) translateX(-50%); /* Maintain horizontal centering */
    opacity: 0;
  }
}

.footer-images-container {
  position: absolute;
  bottom: 0; /* Align to the bottom of the App-body */
  width: 100%; /* Span the full width */
  display: flex; /* Use flexbox to distribute points */
  justify-content: space-between; /* Distribute points evenly */
  padding: 15px 20px; /* Padding on the sides */
  z-index: 1010;
}

.footer-point {
  display: inline-block;
  width: 10px; /* Fixed width */
  height: 10px; /* Fixed height */
  border-radius: 50%; /* Make the point circular */
  background-color: black; /* Default color */
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Smooth transition for scaling and color change */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
}

.footer-point.active {
  transform: scale(1.5); /* Increase the size of the active point */
  background-color: white; /* Change color to white when active */
}
